import React, { useRef, useEffect } from 'react'

import * as styles from './index.module.css'

const ANIMATIONS = {
  fadein: styles.animation_fadeIn,
  open: styles.animation_open
}

export const Modal = ({ children, close, show, background = null, animation = null }) => {
  const animationClass = ANIMATIONS[animation]  

  const modalContainer = useRef()

  const handleClose = (event) => {
    if (event.target === modalContainer.current) {
      close()
    }
  }

  useEffect(() => {
    const handleKeyEvent = (event) => {
      if (event.code === 'Escape') {
        close()
      }
    }

    if (show) {
      document.addEventListener('keydown', handleKeyEvent)
    } else {
      document.removeEventListener('keydown', handleKeyEvent)
    }
  
    return () => {
      document.removeEventListener('keydown', handleKeyEvent)
    }
  }, [show, close])

  if (!show) {
    return null
  }

  return (
    <div
      style={background ? {background: background} : {}}
      ref={modalContainer}
      className={styles.modalContainer}
      onClick={handleClose}
      onKeyPress={() => {}}
      role='button'
      tabIndex={0}
    >
      <div className={`${styles.modal} ${animationClass ? animationClass : ''}`} >
        {
          children
        }
      </div>
    </div>
  )
}