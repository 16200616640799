import { useState, useEffect, useRef } from 'react'

export const useIntersectionObserver = (margin = '200px') => {
  const [isIntersecting, setIsIntersecting] = useState(false) 
  const [observer, setObserver] = useState(null)
  const ref = useRef(null)

  const onChange = (entries) => {
    if (entries[0].isIntersecting) {
      setIsIntersecting(true)
    }
  }

  useEffect(() => {
    if (isIntersecting && observer) {
      observer.disconnect()
    }
  }, [isIntersecting, observer])
  
  useEffect(() => {

    Promise.resolve(
      typeof IntersectionObserver !== 'undefined'
        ? IntersectionObserver
        : import('intersection-observer')
    ).then(() => {
      const newObserver = new IntersectionObserver(onChange, {
        rootMargin: margin
      })
      setObserver(newObserver)
    })
  }, [margin])

  useEffect(() => {
    if (ref && observer) {
      observer.observe(ref.current)
    }
  }, [ref, observer])

  return [isIntersecting, ref]
}