import React, { useState, useEffect, useRef } from 'react';
import { PortfolioCard } from '../PortfolioCard'
import { useIntersectionObserver } from '../../../hooks/useIntersectionObserver';

// software development images
import EdenImg from '../../../images/landing/portfolio/eden_landing.png'
import pandaBTMImg from '../../../images/landing/portfolio/pandabtm_landing.png'
import pandagroupImg from '../../../images/landing/portfolio/pandagroup_landing.png'
import pandanoticiasImg from '../../../images/landing/portfolio/pandanoticias_landing.png'
import pandaserverImg from '../../../images/landing/portfolio/pandaserver_landing.png'
import pandaExchangeImg from '../../../images/landing/portfolio/xch_landing.png'
import xpayImg from '../../../images/landing/portfolio/xpay_landing.png'

// content creation images
import EdenImg_content from '../../../images/landing/portfolio/contentCreation/1contenido_eden.png'
import pandaBTMImg_content from '../../../images/landing/portfolio/contentCreation/6contenido_blogpandabtm.png'
import pandagroupImg_content from '../../../images/landing/portfolio/contentCreation/2contenido_pandagroup.png'
import pandanoticiasImg_content from '../../../images/landing/portfolio/contentCreation/5contenido_pandanoticias.png'
import pandaserverImg_content from '../../../images/landing/portfolio/contentCreation/4contenido_pandaserver.png'
import pandaExchangeImg_content from '../../../images/landing/portfolio/contentCreation/3contenido_pandaxch.png'
import xpayImg_content from '../../../images/landing/portfolio/contentCreation/7contenido_blogxpay.png'

import * as styles from './index.module.css'

const CARDS_IMG_CONTENT = {
	EdenImg: EdenImg_content,
	pandaBTMImg: pandaBTMImg_content,
	pandagroupImg: pandagroupImg_content,
	pandanoticiasImg: pandanoticiasImg_content,
	pandaserverImg: pandaserverImg_content,
	pandaExchangeImg: pandaExchangeImg_content,
	xpayImg: xpayImg_content
}


const CARDS_IMG = {
	EdenImg,
	pandaBTMImg,
	pandagroupImg,
	pandanoticiasImg,
	pandaserverImg,
	pandaExchangeImg,
	xpayImg
}

export const Portfolio = ({ texts }) => {
	const [isIntersecting, ref] = useIntersectionObserver()
	const typeButtons = useRef()
	const numberOfCards = 2
	const [typeOfCards, setTypeOfCards] = useState('development')
	const [cards, setCards] = useState(typeOfCards === 'development'
		? texts.softwareDevelopment
		: texts.contentCreation
	)
	const [cardsToShow, setCardsToShow] = useState([]) 
	const [pageNumber, setPageNumber] = useState(1)
	const cardsImages = typeOfCards === 'development' ? CARDS_IMG : CARDS_IMG_CONTENT   

	useEffect(() => {
		const newCards = typeOfCards === 'development'
			? texts.softwareDevelopment
			: texts.contentCreation

		setCards(newCards)
		setCardsToShow(newCards.slice(0, numberOfCards))
		setPageNumber(1)
	}, [typeOfCards, texts])

	const handleNewpage = () => {
		typeButtons.current.scrollIntoView({ behavior: 'smooth' })
		if (cards.length >= pageNumber * numberOfCards) {
			if (pageNumber * numberOfCards + numberOfCards < cards.length) {
				setCardsToShow(cards.slice(numberOfCards * pageNumber, numberOfCards * pageNumber + numberOfCards)
				)
				setPageNumber(pageNumber+1)	
			} else {
				setCardsToShow(cards.slice(cards.length - numberOfCards))
				setPageNumber(pageNumber+1)
			}
		} else {
			setCardsToShow(cards.slice(0, numberOfCards))
			setPageNumber(1)
		}
	} 

	const handleclick = (type) => {
		setTypeOfCards(type)
	}

	return (
		<section className={styles.section} id='portfolio' ref={ref}>
			<h1 className={styles.mainTitle} >
				{texts && texts.title}
			</h1>
			<h2 className={styles.mainDescription}>
				{texts && texts.description}
			</h2>
			<div className={styles.menuContainer}>
				<button
					onClick={() => {handleclick('development')}}
					className={styles.menuItem}
				>
					{texts && texts.menu[0]}
				</button>
				<button
					onClick={() => {handleclick('content')}}
					className={styles.menuItem}
				>
					{texts && texts.menu[1]}
				</button>
				<div
					ref={typeButtons}
					className={styles.menubar}
					style={typeOfCards === 'development' ? {left: 0} : {left: '50%'}}
				></div>
			</div>
			<div className={styles.cardsContainer}>
				{
					cardsToShow.length && cardsToShow.map((item) => {
						return <PortfolioCard
							{...item}
							mainImg={cardsImages[item.imgName]}
							key={`portfolioCard-${typeOfCards}-${item.imgTitle}`}
							renderImg={isIntersecting}
						/>
					})
				}
			</div>
			<div className={styles.nextPageButtonButton_container}>
				<button className={styles.nextPageButtonButton} onClick={handleNewpage}>
					{texts && texts.nextPageButton}
				</button>
			</div>
		</section>
	)
}