// extracted by mini-css-extract-plugin
export var section = "index-module--section--2wOx8";
export var teamId = "index-module--teamId--2sSNZ";
export var title = "index-module--title--1f9yY";
export var teamMenbers_container = "index-module--teamMenbers_container--3GsEb";
export var teamPar = "index-module--teamPar--w-2Eg";
export var teamMenber_article = "index-module--teamMenber_article--3E9ju";
export var teamMenber_article__bottom = "index-module--teamMenber_article__bottom--1P2ij";
export var teamMenber_button = "index-module--teamMenber_button--2ClVA";
export var teamMenber_button_withModal = "index-module--teamMenber_button_withModal--2lh3W";
export var teamMenber_img = "index-module--teamMenber_img--298FC";
export var teamMenber_infoContainer = "index-module--teamMenber_infoContainer--3d_ak";
export var teamMenber_name = "index-module--teamMenber_name--fR-IX";
export var teamMenber_position = "index-module--teamMenber_position--1IdPJ";
export var modalContainer = "index-module--modalContainer--3v7Sx";
export var modalImg = "index-module--modalImg--3OQFl";
export var modalTitle = "index-module--modalTitle--1cth_";
export var modalDescription = "index-module--modalDescription--3xKm_";
export var modalContainer__background = "index-module--modalContainer__background--2D4Ej";
export var modalContainer__logo = "index-module--modalContainer__logo--3rssv";
export var logo_pulse_modal = "index-module--logo_pulse_modal--oDmAO";