import React, { Fragment } from 'react'

import { useIntersectionObserver } from '../../../hooks/useIntersectionObserver'

import * as styles from './index.module.css'

import community_img from '../../../images/landing/comunity/comunity_img.png'
import cloud_community from '../../../images/landing/comunity/comunity_background.png'

export const Community = ({ texts }) => {
	const [isIntersecting, intersectionRef] = useIntersectionObserver('500px')

	return (
		<section ref={intersectionRef} className={styles.section}>
			{
				isIntersecting && (
					<Fragment>
						<img
							className={styles.Main_img}
							src={community_img}
							alt=''
						/>
						<div className={styles.bottomImgContainer} >
							<h1 className={styles.bottomImgContainer_title}>
								{texts && texts.title}
							</h1>
							<img
								className={styles.bottom_img}
								src={cloud_community}
								alt=''
							/>
						</div>
					</Fragment>
				)
			}
		
		</section>
	)
}