import React from 'react'

import webIcon from '../../../images/landing/portfolio/web_icon.png'
import facebookImage from '../../../images/landing/hero/facebook.png'
import instagramImage from '../../../images/landing/hero/instagram.png'
import mediumImage from '../../../images/landing/hero/medium.png'
import twitterImage from '../../../images/landing/hero/twitter.png'

import * as styles from './index.module.css'

const socialMediaImages = {
	facebook: facebookImage,
	instagram: instagramImage,
	blog: mediumImage,
	twitter: twitterImage
}



export const PortfolioCard = ({ imgTitle, mainImg, mainImgAlt = '', title, description, anchorText, link, socialMedia, renderImg }) => {
	const RRSS =  socialMedia ? Object.keys(socialMedia) : null 

	return (
		<article className={styles.card}>
			<div className={styles.imgContainer}>
				<img src={renderImg ? mainImg : null} alt={mainImgAlt} className={styles.mainImg} />
				<h3 className={styles.imgTitle}>{imgTitle ? imgTitle : title}</h3>
			</div>
			<div className={styles.textContainer}>
				<h3 className={styles.title}>
					{title}
				</h3>
				{
					Array.isArray(description)
						? (
							description.map((item, index) => {
								return <p
									className={styles.description}
									key={`porfolioCard-${title}-description${index}`}
								>
									{item}
									</p>
							})
						)
						: <p className={styles.description}>{description}</p>
				}
				<div
					className={`${styles.contactContainer} ${(link && anchorText && RRSS) ? styles.contactContainer_WithLink : ''}`}
				>
					{
						(link && anchorText) && (
							<a
								target='_blank'
								rel='noreferrer'
								href={link}
								className={styles.anchor}
							>
								<img src={webIcon} alt='Golbo de internet' className={styles.webIcon}/>
								{anchorText}
							</a>
						)
					}
					{
						socialMedia && RRSS.map((item, index) => {
							return (
								<a
									key={`porfolioCards-${imgTitle}-${index}`}
									target='_blank'
									rel='noreferrer'
									href={socialMedia[item]}
									className={styles.SocialMediaItem}
								>
								<img
									src={socialMediaImages[item]}
									alt={`${item} icon`}
								/>
								</a>
							)
						})
					}
				</div>
			</div>
		</article>
	)
}