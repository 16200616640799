import React from 'react'
import { useIntersectionObserver } from '../../../hooks/useIntersectionObserver'
import * as styles from './index.module.css'

import icon1 from '../../../images/landing/services/serviceIcon_1.png'
import icon2 from '../../../images/landing/services/serviceIcon_2.png'
import icon3 from '../../../images/landing/services/serviceIcon_3.png'
import icon4 from '../../../images/landing/services/serviceIcon_4.png'
import icon5 from '../../../images/landing/services/serviceIcon_5.png'
import icon6 from '../../../images/landing/services/serviceIcon_6.png'
import icon7 from '../../../images/landing/services/serviceIcon_7.png'
import icon8 from '../../../images/landing/services/serviceIcon_8.png'

const SERVICES_IMG = [icon1, icon2, icon3, icon4, icon5, icon6, icon7, icon8]

const ServiceItem = ({ img = null, alt = '', title, texts = [], renderImg = false }) => {
	return (
		<article className={styles.service_container} >
			<img className={styles.service_img} src={renderImg ? img : null} alt={alt} />
				<h2 className={styles.service_title} >{title}</h2>
				<ul className={styles.service_list}>
				{
					texts.map((item, index) => {
						return (
							<li key={`serviceItem-${title}-${index}`} className={styles.service_listItem}>
								- {item}
							</li>
						)
					})
				}
				</ul>
		</article>
	)
}


export const Services = ({ texts = [] }) => {
	const [isIntersecting, ref] = useIntersectionObserver()
	const servicesData = texts.serviceItem.map((item, index) => {
		return {...item, img: SERVICES_IMG[index]}
	})

	return (
		<section className={styles.section} id='services' ref={ref}>
			<h1 className={styles.mainTitle} >
				{texts && texts.title}
			</h1>
			<h3 className={styles.mainDescription} >
				{texts && texts.description}
			</h3>
			<div className={styles.items_container}>
				{
					servicesData.length
						? servicesData.map((item, index) => {
							return (
								<ServiceItem
									key={`serviceItem-${index}`}
									title={item.title}
									img={item.img}
									texts={item.texts}
									renderImg={isIntersecting}
								/>
							)
						})
						: null
				}
			</div>
		</section>
	)
}