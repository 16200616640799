import React, { useState, Fragment } from 'react'
import { Link } from 'gatsby'
import hamburgerImg from '../../../images/header/hamburger.svg'
import hamburgerImgClose from '../../../images/header/hamburger_close.svg'
import * as styles from './index.module.css'

import esFlag from '../../../images/header/flag(ES).svg'
import enFlag from '../../../images/header/flag(EN).svg'

export const HamburgerMenu = ({ texts }) => {
	const [showMenu, setShowMenu] = useState(false)
	const [animate, setAnimate] = useState(false)

	const handleShowMenu = () => {
		if (!showMenu) {
			setShowMenu(true)
			setTimeout(() => {
				setAnimate(true)
			})
		} else {
			setAnimate(false)
			setTimeout(() => {
				setShowMenu(false)
			}, 600)
		}
	}
	
	return (
		<Fragment>
			<button onClick={handleShowMenu} className={styles.button}>
				<img
					className={styles.buttonImg}
					src={showMenu ? hamburgerImgClose : hamburgerImg}
					alt={`${!showMenu ? 'abrir menu de hamburguesa' : 'cerrar menu de hamburguesa'}`} />
			</button>
			{
				showMenu && (
					<section className={`${styles.menuSection} ${animate && styles.menuSectionOpen}`}>
						<nav className={styles.menuSection__nav}>
							{
								texts.links.map((item, index) => {
									return (
										<a
											onClick={handleShowMenu}
											key={`hamburgerMenuLink-${index}`}
											className={styles.menuSection__achor}
											href={item.url}
										>
											{item.title}
										</a>
									)
								})
							}
						</nav>
						<div className={styles.flagsContainer}>
							<Link to='/'>
								<img src={esFlag} alt='español' />
							</Link>
							<Link to='/en'>
								<img src={enFlag} alt='ingles' />
							</Link>
						</div>
					</section>
				)
			}
		</Fragment>
	)
}