import React, { useState } from 'react'
import { useIntersectionObserver } from '../../../hooks/useIntersectionObserver'
import * as styles from './index.module.css'
import instagramLogo from '../../../images/landing/hero/instagram.png'
import facebookLogo from '../../../images/landing/hero/facebook.png'
import twitterLogo from '../../../images/landing/hero/twitter.png'
import mediumLogo from '../../../images/landing/hero/medium.png'
import { Modal } from '../../general/Modal'
import ReCAPTCHA from 'react-google-recaptcha'

export const Contact = ({ texts }) => {
	const [showModal, setShowModal] = useState(false)
	const [isIntersecting, ref] = useIntersectionObserver()

	const [formData, setformData] = useState({
		name: '',
		message: '',
	})

	const handleChange = (event) => {
		setformData(oldData => {
			const newData = {...oldData}
			newData[event.target.name] = encodeURI(event.target.value) 
			return newData
		})
	} 

	const handleSubmit = (event) => {
		setShowModal(true)
		event.preventDefault()
	}
	
	const onChangeReCaptcha = (token) => {
		if (token) {
			window.open(`https://mail.google.com/mail/u/0/?fs=1&to=hola@mituannia.ventures&tf=cm&body=${formData.message}&su=${formData.name} quiere trabajar con Mituannia Ventures`)
		}
		setShowModal(false)
	}

	return (
		<section
			className={`${styles.mainSection} ${isIntersecting ? styles.mainSection_background : ''}`}
			id='contact'
			ref={ref}
		>
			<h1 className={styles.mainTitle}>{texts && texts.title}</h1>
			<p className={styles.mainDescription}>{texts && texts.description}</p>
			<form className={styles.form} onSubmit={handleSubmit}>
				<div className={styles.inputContainer}>	
					<input
						required
						type='text'
						name='name'
						placeholder={texts ? texts.namePlaceholder : ''}
						className={styles.input}
						onChange={handleChange}
					/>
				</div>
				<textarea
					required
					name='message'
					placeholder={texts ? texts.messajePlaceholder : ''}
					className={styles.formMessage}
					onChange={handleChange}
				></textarea>
				<button type='submit' className={styles.formButton}>
					{texts && texts.button}
				</button>
			</form>
			<p className={styles.socialMediaTexts}>
				{texts && texts.socialMediaDescription}
			</p>
			<div className={styles.socialMediaContainer}>
				<a
					target='_blank'
					rel='noreferrer' 
					className={styles.socialMedia_item}
					href='https://www.instagram.com/mituanniaventures/'
				>
					<img src={instagramLogo} alt='instagram logo' />
				</a>
				<a
					target='_blank'
					rel='noreferrer' 
					className={styles.socialMedia_item}
					href='https://www.facebook.com/mituannia'
				>
					<img src={facebookLogo} alt='facebook logo' />
				</a>
				<a
					target='_blank'
					rel='noreferrer' 
					className={styles.socialMedia_item}
					href='https://twitter.com/mituannia'
				>
					<img src={twitterLogo} alt='twitter logo' />
				</a>
				<a
					target='_blank'
					rel='noreferrer' 
					className={styles.socialMedia_item}
					href='https://medium.com/mituannia'
				>
					<img src={mediumLogo} alt='medium logo' />
				</a>
				<Modal
					animation='fadein'
					background='#00000066'
					show={showModal}
					close={() => {setShowModal(false)}}
				>
					<ReCAPTCHA
						className={styles.captcha}
						sitekey='6Leq7-wbAAAAAE4-YMAj4nee-Hf9UYYUynBqniBG'
						onChange={onChangeReCaptcha}
					/>
				</Modal>
			</div>
		</section>
	)
}