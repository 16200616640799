import React from 'react'
import { Layoud } from '../../general/Layoud'
import { Hero } from '../Hero'
import { Services } from '../Services'
import { Community } from '../Community'
import { Team } from '../Team'
import { Contact } from '../Contact'
import { Portfolio } from '../Portfolio'

export const HomePage = ({ texts, lang }) => {

	return (
		<Layoud lang={lang}>
			<Hero texts={texts.hero} />
			<Services texts={texts.services} />
			<Portfolio texts={texts.portfolio} />
			<Community texts={texts.community} />
			<Team texts={texts.team} /> 
			<Contact texts={texts.contant}/>
		</Layoud>
	)
}