import React, { useState } from 'react'
import { useIntersectionObserver } from '../../../hooks/useIntersectionObserver'
import { Modal } from '../../general/Modal'
import * as styles from './index.module.css'

import cloudImg from '../../../images/footer/modal/cloud.png'
import modalLogo from '../../../images/footer/modal/logo.png'

import annaImg from '../../../images/landing/team/anna.png'
import arleyImg from '../../../images/landing/team/arley.png'
import baudilioImg from '../../../images/landing/team/baudilio.png'
import carlosImg from '../../../images/landing/team/carlos.png'
import hildangelliImg from '../../../images/landing/team/Hildangelli.png'
import jananImg from '../../../images/landing/team/janan.png'
import joseCarreroImg from '../../../images/landing/team/joseCarrero.png'
import juanCarreroImg from '../../../images/landing/team/juanDiego.png'
import karenImg from '../../../images/landing/team/karen.png'
import lesterImg from '../../../images/landing/team/lester.png'
import thorImg from '../../../images/landing/team/thor.png'
import typsonImg from '../../../images/landing/team/typson.png'
import victorImg from '../../../images/landing/team/victor.png'

const teamMembersHeros = {
	annaImg,
	arleyImg,
	baudilioImg,
	carlosImg,
	hildangelliImg,
	jananImg,
	joseCarreroImg,
	juanCarreroImg,
	karenImg,
	lesterImg,
	thorImg,
	typsonImg,
	victorImg
}

const TeamMember = ({ name = '', img = null, alt = '',  position = [], alingtop, renderImg, modalDescription}) => {
	const [showModal, setShowModal] = useState(false)

	const handlemodal = () => {
		if (modalDescription) {
			setShowModal(true)
		}
	}

	return (
		<article
			className={`
			${styles.teamMenber_article}
			 ${alingtop ? styles.teamMenber_article__bottom : ''}
			`}
		>
			<button
				className={`${styles.teamMenber_button} ${modalDescription ? styles.teamMenber_button_withModal : ''}`}
				onClick={handlemodal}
			>
				<img
					className={styles.teamMenber_img}
					src={renderImg ? img : null}
					alt={alt}
				/>
				<div className={styles.teamMenber_infoContainer}>
					<h3 className={styles.teamMenber_name}>
						{name}
					</h3>
					{
						position.map((item, index) => {
							return (
								<h4 key={`member-${name}-position-${index}`} className={styles.teamMenber_position}>
									{item}
								</h4>
							)
						})
					}
				</div>
			</button>
			<Modal
				animation='fadein'
				background='#00000066'
				show={showModal}
				close={() => {setShowModal(false)}}
			>
				<div className={styles.modalContainer}>
					<img src={cloudImg} alt='imagen nubes' className={styles.modalContainer__background}/>
					<img src={modalLogo} alt='mituannia logo' className={styles.modalContainer__logo}/>
					<img src={img} alt={alt} className={styles.modalImg}/>
					<h3 className={styles.modalTitle}>{name}</h3>
					<p className={styles.modalDescription} >
						{ modalDescription }
					</p>
				</div>
			</Modal>
		</article>
	)
}

export const Team = ({ texts }) => {
	const [isIntersecting, ref] = useIntersectionObserver()

	const getMembers = () => {
		let result = []
		for (let i = 0, j = 1; i < texts.teamMembers.length; i = i + 2, j++) {
			const isPar = j % 2 === 0

			result.push(
				<div className={styles.teamPar} key={`teamMember-par-${[i]}`} >
					<TeamMember
						alingtop={isPar ? true : false}
						key={`teamMember-${texts.teamMembers[i].name}`}
						name={texts.teamMembers[i].name}
						img={teamMembersHeros[texts.teamMembers[i].imgName]}
						position={texts.teamMembers[i].position}
						renderImg={isIntersecting}
						modalDescription={texts.teamMembers[i].modalDescription}
					/>
					{
						texts.teamMembers[i+1] && (
							<TeamMember
								alingtop={isPar ? false : true}
								key={`teamMember-${texts.teamMembers[i+1].name}`}
								name={texts.teamMembers[i+1].name}
								img={teamMembersHeros[texts.teamMembers[i+1].imgName]}
								position={texts.teamMembers[i+1].position}
								renderImg={isIntersecting}
								modalDescription={texts.teamMembers[i+1].modalDescription}
							/>
						)
					}
				</div>
			)
		} 
		return result
	}

	return (
		<section className={styles.section} ref={ref}>
			<div id='team' className={styles.teamId}/>
			<h1 className={styles.title}>
					{texts && texts.mainTitle}
			</h1>
			<div
				className={styles.teamMenbers_container}
			>
			{
				getMembers()
			}
			</div>
		</section>
	)
}