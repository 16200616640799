// extracted by mini-css-extract-plugin
export var card = "index-module--card--3GX5U";
export var fadeIn = "index-module--fadeIn--34PaA";
export var imgContainer = "index-module--imgContainer--1gR8M";
export var mainImg = "index-module--mainImg--1QkiO";
export var imgTitle = "index-module--imgTitle--Jsskm";
export var textContainer = "index-module--textContainer--10D8z";
export var title = "index-module--title--sSzkP";
export var description = "index-module--description--2as7h";
export var contactContainer = "index-module--contactContainer--35m7X";
export var anchor = "index-module--anchor--3yWbT";
export var webIcon = "index-module--webIcon--3Spip";
export var SocialMediaItem = "index-module--SocialMediaItem--1ecl8";
export var contactContainer_WithLink = "index-module--contactContainer_WithLink--1RnIU";