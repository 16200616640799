import React from 'react'

import logo from '../../../images/landing/hero/mituanniaLogo.png'
import clouds from '../../../images/landing/hero/clouds.png'

import instagramLogo from '../../../images/landing/hero/instagram.png'
import facebookLogo from '../../../images/landing/hero/facebook.png'
import twitterLogo from '../../../images/landing/hero/twitter.png'
import mediumLogo from '../../../images/landing/hero/medium.png'

import * as styles from './index.module.css'

export const Hero = ({ texts }) => {
	return (
		<section className={styles.section} >
				<img
					src={logo}
					alt='Logo de Mituannia'
					className={styles.logo_img}
				/>
				<img
					src={clouds}
					alt='nubes'
					className={styles.clouds_img}
				/>
			<h1 
				className={styles.title}
			>
				{texts && texts.title}
			</h1>
			<aside className={styles.socialMedia}>
				<a
					target='_blank'
					rel='noreferrer' 
					className={styles.socialMedia_item}
					href='https://www.instagram.com/mituanniaventures/'
				>
					<img src={instagramLogo} alt='instagram logo' />
				</a>
				<a
					target='_blank'
					rel='noreferrer' 
					className={styles.socialMedia_item}
					href='https://www.facebook.com/mituannia'
				>
					<img src={facebookLogo} alt='facebook logo' />
				</a>
				<a
					target='_blank'
					rel='noreferrer' 
					className={styles.socialMedia_item}
					href='https://twitter.com/mituannia'
				>
					<img src={twitterLogo} alt='twitter logo' />
				</a>
				<a
					target='_blank'
					rel='noreferrer' 
					className={styles.socialMedia_item}
					href='https://medium.com/mituannia'
				>
					<img src={mediumLogo} alt='medium logo' />
				</a>
			</aside>
		</section>
	)
}