import React, { Fragment } from 'react'
import { Link } from 'gatsby'
import { HamburgerMenu } from '../HamburgerMenu'
import { useResizeListener } from '../../../hooks/useResizeListener'
import textsES from '../../../languages/header/es.json'
import textsEN from '../../../languages/header/en.json'
import { LanguageSelection } from '../LanguageSelection'

import logo from '../../../images/header/mv_logo.png'

import * as styles from './index.module.css'

export const Header = ({ lang = 'es' }) => {
	const texts = lang === 'es' ? textsES : textsEN
	const useHamburgerMenu = useResizeListener(500)

	return (
		<header className={styles.header}>
			<Link to='/'>
				<img
					src={logo}
					alt='logo Mituannia'
					className={styles.logo}	
				/>
			</Link>
			{
				!useHamburgerMenu
					? (
						<nav className={styles.nav}>
						{
							texts && texts.links.map((item, index) => {
								return (
									<Fragment key={`headerLink-${index}`}>
										<a href={item.url} className={styles.anchor}>
											{item.title}
										</a>
										{
											index < texts.links.length-1 && <span> | </span>
										}
									</Fragment>	
								)
							})
						}
							<LanguageSelection lang={lang}/>
						</nav>
					)
				: <HamburgerMenu texts={texts}/>
			}
		</header>
	)
}