import React, { Fragment } from 'react'
import { Header } from '../Header'
import { Footer } from '../Footer' 
import { Helmet } from 'react-helmet'

export const Layoud = ({ children, lang }) => {
	return (
		<Fragment>
			<Helmet>
				<title>Mituannia Ventures</title>
				<meta name='keywords' content="Mituannia Ventures, Typson Sánchez, Anna Lezama, Innovación y Emprendimiento, Software Development, Web Desing, Cloud Computing, Marketing Digital, Outsourcing"/>
				<meta name='description' content='Empresa joven colombiana, nacida con una clara vocación innovadora, buscando dar consultorías en tecnologías de la información, especializados en el desarrollo de aplicaciones, marketing, outsourcing y soluciones de negocio, ofreciendo nuevas alternativas con el objetivo de dar una respuesta integral y eficaz a sus clientes.' />
				<meta name='author' content='Mituannia Ventures' />
				<meta name='robots' content='index, follow' />
				<meta itemprop='name' content='Mituannia Ventures' />
				<meta itemprop='brand' content='Mituannia Ventures' />
				<meta itemprop='legalName' content='Mituannia Ventures SAS' />
				<meta itemprop='description' content='Empresa joven colombiana, nacida con una clara vocación innovadora, buscando dar consultorías en tecnologías de la información, especializados en el desarrollo de aplicaciones, marketing, outsourcing y soluciones de negocio, ofreciendo nuevas alternativas con el objetivo de dar una respuesta integral y eficaz a sus clientes.' />
				{/* <meta itemprop='image' content='http://mituannia.ventures/img/logo_shema.png' /> */}
				
				<meta property='twitter:account_id' content='453619615' />
				<meta name='twitter:card' content='summary_large_image' />
				<meta name='twitter:site' content='@mituannia' />
				<meta name='twitter:creator' content='@mituannia' />
				<meta name='twitter:title' content='Mituannia Ventures' />
				<meta name='twitter:title' content='Mituannia Ventures' />
				<meta name='twitter:description' content='Empresa joven colombiana, nacida con una clara vocación innovadora, buscando dar consultorías en tecnologías de la información, especializados en el desarrollo de aplicaciones, marketing, outsourcing y soluciones de negocio, ofreciendo nuevas alternativas con el objetivo de dar una respuesta integral y eficaz a sus clientes.' />
				{/* <meta name='twitter:image' content='http://mituannia.ventures/img/logo_shema.png' /> */}
				<meta property='og:title' content='Mituannia Ventures' />
				<meta property='og:type' content='website' />
				<meta property='og:url' content='http://mituannia.ventures/' />
				{/* <meta property='og:image' content='http://mituannia.ventures/img/logo_shema.png' /> */}
				<meta property='og:description' content='Empresa joven colombiana, nacida con una clara vocación innovadora, buscando dar consultorías en tecnologías de la información, especializados en el desarrollo de aplicaciones, marketing, outsourcing y soluciones de negocio, ofreciendo nuevas alternativas con el objetivo de dar una respuesta integral y eficaz a sus clientes.' />
				<meta property='og:site_name' description='Mituannia Ventures'/>
			</Helmet>
			<Header lang={lang}/>
			{
				children
			}
		 <Footer lang={lang}/>
		</Fragment>
	)
}  