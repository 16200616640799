import React, { useState } from 'react'
import { useIntersectionObserver } from '../../../hooks/useIntersectionObserver'
import { Modal } from '../Modal'

import textsES from '../../../languages/footer/es.json'
import textsEN from '../../../languages/footer/en.json'

import * as styles from './index.module.css'
import footerLogo from '../../../images/footer/logo.png'

import modalLogo from '../../../images/footer/modal/logo.png'
import cloudImg from '../../../images/footer/modal/cloud.png'

export const Footer = ({ lang }) => {
	const [isIntersecting, ref] = useIntersectionObserver()
	const texts = lang === 'es' ? textsES : textsEN
	const [modalText, setModalText] = useState(null)
	const [showModal, setShowModal] = useState(false)

	const handleModal = (text) => {
		setModalText(text)
		setShowModal(true)
	}

	const closeModal = () => {
		setShowModal(false)
	}

	return (
		<footer 
			className={`${styles.footer} ${isIntersecting ? styles.footer_background : ''}`}
			ref={ref}
		>
			<img
				className={styles.mainImage}
				src={footerLogo}
				alt='logo mituannia'
			/>
			<section className={styles.infoSection}>
				<nav className={styles.nav}>
					<h3 className={styles.nav_title}>
						{texts && texts.colum1.title}
					</h3>
					{
						texts.colum1.links.map((item, index) => {
							return (
								<button
									onClick={() => {handleModal(item)}}
									className={styles.nav1_button}
									key={`footerColum1-item-$${index}`}
								>
									{item.title}
								</button>
							)
						})
					}
					<Modal close={closeModal} show={showModal} animation='open'>
						<div className={styles.modalContainer}>
							<img src={cloudImg} alt='imagen nubes' className={styles.modalContainer__background}/>
							<img src={modalLogo} alt='mituannia logo' className={styles.modalContainer__logo}/>
							<h3>
								{modalText && modalText.title}:
							</h3>
							<p>
								{modalText && modalText.text}
							</p>
						</div>
					</Modal>
				</nav>
				<nav className={`${styles.nav} ${styles.bigNav}`}>
					<h3 className={styles.nav_title}>
						{texts && texts.colum2.title}
					</h3>
					{
						texts.colum2.links.map((item, index) => {
							return (
								<a
									target='_blank'
									rel='noreferrer'
									href={item.url}
									className={styles.nav2_anchor}
									key={`footerColum2-item-$${index}`}
								>
									{item.title}
								</a>
							)
						})
					}
				</nav>
				<nav className={styles.nav}>
					<h3 className={styles.nav_title}>
						{texts && texts.colum3.title}
					</h3>
					{
						texts.colum3.links.map((item, index) => {
							const isLastItem = index === texts.colum3.links.length - 1
							return (
								<a
									target='_blank'
									rel='noreferrer'
									href={item.url}
									className={isLastItem ? styles.nav_title : styles.nav3_anchor}
									key={`footerColum3-item-$${index}`}
								>
									{item.title}
								</a>
							)
						})
					}
				</nav>
			</section>
		</footer>
	)
}