// extracted by mini-css-extract-plugin
export var mainSection = "index-module--mainSection--3bTsz";
export var mainSection_background = "index-module--mainSection_background--1o1lh";
export var mainTitle = "index-module--mainTitle--28_Vn";
export var mainDescription = "index-module--mainDescription--1cQqS";
export var socialMediaTexts = "index-module--socialMediaTexts--2YXXM";
export var form = "index-module--form--18Onr";
export var input = "index-module--input--v9niq";
export var formMessage = "index-module--formMessage--2kfT_";
export var inputContainer = "index-module--inputContainer--2lZRg";
export var formButton = "index-module--formButton--2Rx4y";
export var socialMediaContainer = "index-module--socialMediaContainer--2Ou4q";
export var socialMedia_item = "index-module--socialMedia_item--17Yby";
export var captcha = "index-module--captcha--YmUUl";