import React, { useState } from 'react'
import { Link } from 'gatsby'
import esFlag from '../../../images/header/flag(ES).svg'
import enFlag from '../../../images/header/flag(EN).svg'

import * as styles from './index.module.css' 

export const LanguageSelection = ({ lang }) => {
	const [showMenu, setShowMenu] = useState(false)
	const currentLanguagueFlag = lang === 'es' ? esFlag : enFlag 
	const onclick = () => {
		setShowMenu(!showMenu)
	}

	return (
		<button
			className={styles.mainContainer}
			onClick={onclick}

		>
			<div
				className={styles.mainImgContainer}
			>
				<img
					className={styles.mainImg}
					src={currentLanguagueFlag}
					alt='bandera'
				/>
			</div>
			{
				showMenu && (
					<div className={styles.selectionMenu}>
						<Link to='/'>
							<img src={esFlag} alt='bandera' className={styles.mainImg} />
						</Link>
						<Link to='/en'>
							<img src={enFlag} alt='bandera' className={styles.mainImg} />
						</Link>
					</div>
				)
			}
		</button>
	)
}