// extracted by mini-css-extract-plugin
export var footer = "index-module--footer--3A428";
export var footer_background = "index-module--footer_background--4y4Es";
export var mainImage = "index-module--mainImage--23n7U";
export var logo_pulse = "index-module--logo_pulse--14bZv";
export var infoSection = "index-module--infoSection--mbPdk";
export var nav = "index-module--nav--2R28e";
export var nav_title = "index-module--nav_title--2Xcnu";
export var nav1_button = "index-module--nav1_button--2ezte";
export var nav2_anchor = "index-module--nav2_anchor--BeY2n";
export var nav3_anchor = "index-module--nav3_anchor--1Q2uX";
export var modalContainer = "index-module--modalContainer--2oSCP";
export var modalContainer__background = "index-module--modalContainer__background--3_4zx";
export var modalContainer__logo = "index-module--modalContainer__logo--3-K4M";
export var logo_pulse_modal = "index-module--logo_pulse_modal--1nPsB";
export var bigNav = "index-module--bigNav--p7Qo_";