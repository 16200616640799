// extracted by mini-css-extract-plugin
export var section = "index-module--section--2XSs2";
export var mainTitle = "index-module--mainTitle--1zU-L";
export var mainDescription = "index-module--mainDescription--3QG36";
export var items_container = "index-module--items_container--2zFOC";
export var service_container = "index-module--service_container--1ZNJW";
export var service_img = "index-module--service_img--2Glnf";
export var service_title = "index-module--service_title--3_mGd";
export var service_infoContainer = "index-module--service_infoContainer--2vzpL";
export var service_list = "index-module--service_list--2TeI-";
export var service_listItem = "index-module--service_listItem--kp_EA";